<template>
	<var-skeleton :rows="4" :loading="loading">
		<var-dialog
			title="Delete Command"
			message="Are you sure you want to delete the command?"
			v-model:show="show"
			confirm-button-text="Delete"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose"
		/>
	</var-skeleton>
</template>

<script>

import request from "../request";

export default {
	name: "DeleteCommandModal",
	emits: ['onDeleteCommand'],
	props: {
		project: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			id: null,
			show: false,
			loading: false,
			onBeforeClose: (action, done) => {
				if (action === 'confirm') {
					this.loading = true
					request.delete(process.env.VUE_APP_BASE_URL + '/projects/' + this.project.id + '/commands/' + this.id)
						.then(data => {
							this.loading = false
							this.$emit('onDeleteCommand')
							done()
						})
						.catch(error => {
							alert(error.response.data.message)
						})
				} else {
					done()
				}
			},
		}
	}
}
</script>

<style scoped>

</style>