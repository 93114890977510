<script setup>
import {ref} from "vue"
import request from "../request"

const loading = ref(false)

const show = ref(false)
const id = ref(null)
const type = ref(null)

defineExpose({
	id,
	type,
	show
})

const emit = defineEmits(['onAfterDeletingIntegration'])

const onBeforeClose = (action, done) => {
	if (action === 'confirm') {
		loading.value = true
		request.delete(process.env.VUE_APP_BASE_URL + `/webhooks/${id.value}/${type.value}`)
			.then(data => {
				loading.value = false
				emit('onAfterDeletingIntegration')
				done()
			})
			.catch(error => {
				loading.value = false
				alert(error.response.data.message)
			})
	} else {
		done()
	}
}
</script>

<template>
	<var-skeleton :rows="4" :loading="loading">
		<var-dialog
			title="Delete Integration"
			message="Are you sure you want to delete the integration?"
			v-model:show="show"
			confirm-button-text="Delete"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose"
		/>
	</var-skeleton>
</template>

<style scoped>

</style>