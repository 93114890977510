<template>
	<div class="var-elevation--2 p-3 mb-3">
		<div class="d-flex justify-content-between align-content-center">
			<div class="d-flex justify-content-center align-items-center gap-3">
				<i :class="`bi bi-${type}`"></i>
				<h4 class="align-self-center m-0">{{ channel }}</h4>
			</div>
			<div class="d-flex justify-content-center align-items-center gap-3">
				<h5 class="m-0">{{ badge }}</h5>
				<var-button @click="$emit('onDeleteClick', type, id)" type="danger" round>
					<var-icon name="trash-can" />
				</var-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "IntegrationCell",
	emits: ['onDeleteClick'],
	props: {
		channel: {
			type: String,
			required: true
		},
		badge: {
			type: Number,
			required: true
		},
		id: {
			type: Number,
			required: true
		},
		type: {
			type: String,
			required: true
		}
	},
	data() {
		return {
		}
	}
}
</script>

<style scoped>

</style>