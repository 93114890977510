<template>
	<var-skeleton :rows="4" :loading="loading">
		<var-dialog
			title="Delete Environment File"
			message="Are you sure you want to delete the environment file?"
			v-model:show="show"
			confirm-button-text="Delete"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose"
		/>
	</var-skeleton>
</template>

<script>
import request from "../request";
export default {
	name: "DeleteEnvironmentFileModal",
	emits: ['onEnvironmentFileChange'],
	props: {
		serverId: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			id: null,
			show: false,
			loading: false,
			onBeforeClose: (action, done) => {
				if (action === 'confirm') {
					this.loading = true

					request.delete(process.env.VUE_APP_BASE_URL + '/servers/' + this.serverId + '/environment_files/' + this.id)
						.then(data => {
							this.loading = false
							this.$emit('onEnvironmentFileChange')
							done()
						})
						.catch(error => {
							this.loading = false
							alert(error.response.data.message)
						})
				} else {
					done()
				}
			},
		}
	}
}
</script>

<style scoped>

</style>