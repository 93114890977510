<template>
	<var-style-provider :style-vars="{ '--dialog-width': '420px'}">
		<var-dialog
			v-model:show="show"
			confirm-button-text="Update"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose">
			<template #title>
				Update Environment File
			</template>

			<var-skeleton :rows="7" :loading="loading">
				<var-form ref="form">
					<var-input
						placeholder="Path"
						:rules="[v => !!v || 'File path cannot be empty']"
						v-model="formData.path"
						class="mb-3"
					/>
					<var-input
						placeholder="Content"
						:rules="[v => !!v || 'File content cannot be empty']"
						textarea
						v-model="formData.content"
						class="mb-3"
					/>
				</var-form>
			</var-skeleton>
		</var-dialog>
	</var-style-provider>
</template>

<script>
import {reactive, ref} from "vue";
import request from "../request";

export default {
	name: "UpdateEnvironmentFileModal",
	emits: ['onEnvironmentFileChange'],
	props: {
		serverId: {
			type: Number,
			required: true
		}
	},
	watch: {
		show(newVal, oldVal) {
			if (newVal) {
				this.getEnvironmentFile()
			}
		}
	},
	setup() {
		const formData = reactive({
			path: null,
			content: null
		})

		const form = ref(null)

		return {
			form,
			formData
		}
	},
	data() {
		return {
			loading: false,
			show: false,
			id: null,
			onBeforeClose: (action, done) => {
				if (action === 'confirm') {
					this.form.validate().then(validated => {
						if (!validated) {
							return
						}

						this.loading = true

						request.put(process.env.VUE_APP_BASE_URL + '/servers/' + this.serverId + '/environment_files/' + this.id, this.formData)
							.then(data => {
								this.loading = false
								this.emit('onEnvironmentFileChange')
								done()
							})
							.catch(error => {
								this.loading = false
								alert(error.response.data.message)
							})
					})
				} else {
					done()
				}
			}
		}
	},
	methods: {
		getEnvironmentFile() {
			this.loading = true
			request.get(process.env.VUE_APP_BASE_URL + '/servers/' + this.serverId + '/environment_files/' + this.id)
				.then(data => {
					this.formData.path = data.path
					this.formData.content = data.content

					this.loading = false
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		}
	}
}
</script>

<style scoped>

</style>