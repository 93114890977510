<template>
	<var-style-provider :style-vars="{ '--cell-font-size': '1.5rem' }">
		<div class="var-elevation--2 p-3 mb-3">
			<div class="d-flex justify-content-between align-content-center">
				<var-cell
					:title="path"
					:desc="'Last Updated: ' + lastUpdated"
				/>
				<div class="d-flex justify-content-center align-items-center gap-3">
					<var-button @click="$emit('onEditClick', id)" type="info" round>
						<var-icon name="wrench" />
					</var-button>
					<var-button @click="$emit('onDeleteClick', id)" type="danger" round>
						<var-icon name="window-close" />
					</var-button>
				</div>
			</div>
		</div>
	</var-style-provider>
</template>

<script>
export default {
	name: "EnvironmentFileCell",
	emits: ['onEditClick', 'onDeleteClick'],
	props: {
		id: {
			type: Number,
			required: true
		},
		path: {
			type: String,
			required: true
		},
		lastUpdated: {
			type: String,
			required: true
		}
	}
}
</script>

<style scoped>

</style>