<script setup>
import {useRouter} from "vue-router"

const router = useRouter()

const props = defineProps({
	items: {
		type: Array,
		required: false
	},
	currentItemTitle: {
		type: String,
		required: true
	}
})

const navigate = (to, params) => {
	router.push({
		name: to,
		params
	})
}
</script>

<template>
	<var-breadcrumbs class="mb-2">
		<var-breadcrumb v-for="item in items" @click="navigate(item.to, item.params)">{{ item.title }}</var-breadcrumb>
		<var-breadcrumb>{{ currentItemTitle }}</var-breadcrumb>
	</var-breadcrumbs>
</template>