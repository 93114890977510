<template>
	<var-skeleton :rows="7" :loading="loading">
		<breadcrumbs :current-item-title="formData.name" :items="breadcrumbItems" />
		<var-card
			title="Server Details"
			class="mb-4"
		>
			<template #extra>
				<var-form ref="form" :disabled="loadingSave">
					<var-input
						placeholder="Server name"
						:rules="[v => !!v || 'The name cannot be empty']"
						v-model="formData.name"
						class="mb-4"
					/>
					<var-row :gutter="10">
						<var-col :span="16">
							<var-input
								placeholder="Host Name"
								:rules="[v => !!v || 'The host name cannot be empty']"
								v-model="formData.host_name"
								class="mb-4"
							/>
						</var-col>
						<var-col :span="8">
							<var-input
								placeholder="Port"
								:rules="[v => !!v || 'The port cannot be empty']"
								v-model="formData.port"
								class="mb-4"
							/>
						</var-col>
					</var-row>
					<var-select
						placeholder="Authorization Type"
						:rules="[v => v.length >= 1 || 'Auth type cannot be empty']"
						v-model="formData.authorization_type"
						class="mb-4"
					>
						<var-option label="SSH Key" value="ssh_key"/>
						<var-option label="Password" value="password"/>
					</var-select>
					<var-row
						v-if="formData.authorization_type === 'password'"
						:gutter="10"
					>
						<var-col :span="12">
							<var-input
								placeholder="Username"
								v-model="formData.username"
								class="mb-4"
							/>
						</var-col>
						<var-col :span="12">
							<var-input
								type="password"
								placeholder="Password"
								v-model="formData.password"
								class="mb-4"
							/>
						</var-col>
					</var-row>
					<var-input
						v-else
						placeholder="Username"
						v-model="formData.username"
						class="mb-4"
					/>
					<var-input
						v-if="formData.authorization_type === 'ssh_key'"
						placeholder="SSH key"
						textarea
						disabled
						v-model="formData.ssh_key"
						class="mb-4"
					/>
					<var-input
						placeholder="Deployment Path"
						v-model="formData.path"
						class="mb-4"
					/>
					<var-select
						v-if="branches"
						placeholder="Default branch"
						:rules="[v => v.length >= 1 || 'You should pick a default branch']"
						v-model="formData.default_branch"
						class="mb-4"
					>
						<var-option v-for="branch in branches" :label="branch.name" :value="branch.name"/>
					</var-select>
					<var-row justify="space-between">
						<var-col :span="8">
							<h6>Automatic Deployment</h6>
							<var-switch
								@click="switchAutomaticDeployments"
								v-model="formData.automatic_deployment"
								:loading="loadingDeployments"
							/>
						</var-col>
						<var-col :span="8" class="text-end">
							<var-button type="primary" @click="save" :loading="loadingSave">Save</var-button>
						</var-col>
					</var-row>
				</var-form>
			</template>
			"mb-4"
		</var-card>

		<var-card
			title="Environment Files"
			class="mb-4"
		>

			<template #extra>
				<environment-file-cell
					v-for="environmentFile in environmentFiles"
					:id="environmentFile.id"
					:path="environmentFile.path"
					:last-updated="environmentFile.updated_at"
					@onEditClick="onEnvironmentFileEditClick"
					@onDeleteClick="onEnvironmentFileDeleteClick"
				/>

				<var-button type="primary" @click="$refs.createEnvironmentFileModal.show = true">Create</var-button>
			</template>
		</var-card>

		<var-card
			title="Integrations"
		>
			<template #extra>
				<var-skeleton :rows="10" :loading="loading">
					<integration-cell
						v-for="integration in slackIntegrations"
						:channel="integration.channel"
						:badge="integration.badge"
						:id="integration.id"
						type="slack"
						@onDeleteClick="deleteIntegration"
					/>
				</var-skeleton>
				<var-skeleton :rows="10" :loading="loading">
					<integration-cell
						v-for="integration in discordIntegrations"
						:channel="integration.channel"
						:badge="integration.badge"
						:id="integration.id"
						type="discord"
						@onDeleteClick="deleteIntegration"
					/>
				</var-skeleton>

				<var-space>
					<var-button type="primary" @click="redirectToSlack" :loading="loadingSlack">Add Slack Channel</var-button>
					<var-button type="primary" @click="redirectToDiscord" :loading="loadingDiscord">Add Discord Channel</var-button>
				</var-space>
			</template>
		</var-card>
	</var-skeleton>

	<create-environment-file-modal
		v-if="formData.id"
		ref="createEnvironmentFileModal"
		:server-id="formData.id"
		@onEnvironmentFileChange="refresh"
	/>

	<update-environment-file-modal
		v-if="formData.id"
		ref="updateEnvironmentFileModal"
		:server-id="formData.id"
		@onEnvironmentFileChange="refresh"
	/>

	<delete-environment-file-modal
		v-if="formData.id"
		ref="deleteEnvironmentFileModal"
		:server-id="formData.id"
		@onEnvironmentFileChange="refresh"
	/>

	<delete-integration-modal ref="deleteIntegrationModal" @onAfterDeletingIntegration="refresh" />

</template>

<script>
import {reactive, ref} from 'vue'
import request from "../request";
import EnvironmentFileCell from "../components/EnvironmentFileCell";
import CreateEnvironmentFileModal from "../components/CreateEnvironmentFileModal";
import UpdateEnvironmentFileModal from "../components/UpdateEnvironmentFileModal";
import DeleteEnvironmentFileModal from "../components/DeleteEnvironmentFileModal";
import IntegrationCell from "../components/IntegrationCell.vue";
import {Snackbar} from "@varlet/ui";
import DeleteCommandModal from "../components/DeleteCommandModal.vue";
import DeleteIntegrationModal from "../components/DeleteIntegrationModal.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
	name: "ServerDetails",
	components: {
		Breadcrumbs,
		DeleteIntegrationModal,
		DeleteCommandModal,
		IntegrationCell,
		DeleteEnvironmentFileModal,
		UpdateEnvironmentFileModal,
		CreateEnvironmentFileModal,
		EnvironmentFileCell
	},
	setup() {
		const formData = reactive({
			id: null,
			name: null,
			host_name: null,
			username: null,
			port: null,
			password: null,
			authorization_type: null,
			path: null,
			default_branch: null,
			automatic_deployment: null
		})

		const form = ref(null)

		return {
			form,
			formData
		}
	},
	created() {
		this.getBranches()
		this.getServerDetails()
	},
	data() {
		return {
			loading: true,
			loadingDeployments: false,
			loadingDiscord: false,
			loadingSlack: false,
			breadcrumbItems: [
				{
					title: 'Projects',
					to: 'Projects',
					params: {}
				}
			],
			branches: null,
			environmentFiles: [],
			slackIntegrations: [],
			discordIntegrations: [],
			loadingSave: false
		}
	},
	methods: {
		getBranches() {
			this.loading = true;
			request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.$route.params.project_id + '/branches')
				.then(data => {
					this.loading = false
					this.branches = data
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		getServerDetails() {
			this.loading = true
			request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.$route.params.project_id + '/servers/' + this.$route.params.server_id)
				.then(data => {
					this.loading = false

					this.breadcrumbItems.push({
						title: data.data.project,
						to: 'ProjectDetails',
						params: {
							id: this.$route.params.project_id
						}
					})

					this.collectData(data.data)
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		switchAutomaticDeployments() {
			this.loadingDeployments = true
			request.put(process.env.VUE_APP_BASE_URL + '/projects/' + this.$route.params.project_id + '/servers/' + this.formData.id, {
				automatic_deployment: !this.formData.automatic_deployment
			})
				.then(data => {
					this.loadingDeployments = false
					this.collectData(data)
				})
				.catch(error => {
					this.loadingDeployments = false
					console.log(error)
					this.formData.automatic_deployment = !this.formData.automatic_deployment
				})
		},
		save() {
			this.loadingSave = true
			request.put(process.env.VUE_APP_BASE_URL + '/projects/' + this.$route.params.project_id + '/servers/' + this.formData.id, this.formData)
				.then(data => {
					this.loadingSave = false
					this.collectData(data)
				})
				.catch(error => {
					this.loadingSave = false
					console.log(error)
				})
		},
		collectData(data) {
			this.formData.id = data.id
			this.formData.name = data.name
			this.formData.host_name = data.host_name
			this.formData.username = data.username
			this.formData.port = data.port
			this.formData.authorization_type = data.authorization_type

			this.formData.ssh_key = data.ssh_key_pub
			// TODO: change this in production
			this.formData.password = data.password

			this.formData.automatic_deployment = Boolean(data.automatic_deployment)
			this.formData.default_branch = data.default_branch
			this.formData.path = data.path

			this.environmentFiles = data.environment_files
			this.slackIntegrations = data.slack_webhooks
			this.discordIntegrations = data.discord_webhooks
		},
		onEnvironmentFileEditClick(id) {
			this.$refs.updateEnvironmentFileModal.id = id
			this.$refs.updateEnvironmentFileModal.show = true
		},
		onEnvironmentFileDeleteClick(id) {
			this.$refs.deleteEnvironmentFileModal.id = id
			this.$refs.deleteEnvironmentFileModal.show = true
		},
		onEnvironmentFileChange() {
			this.getServerDetails()
		},
		redirectToSlack() {
			this.loadingSlack = true
			request.post(process.env.VUE_APP_BASE_URL + '/servers/' + this.$route.params.server_id + '/generate_slack_link')
				.then(data => {
					this.loadingSlack = false
					window.open(data.redirect_url, '_self')
				})
				.catch(error => {
					this.loadingSlack = false
					Snackbar.error("Failed to add the integration")
				})
		},
		redirectToDiscord() {
			this.loadingDiscord = true
			request.post(process.env.VUE_APP_BASE_URL + '/servers/' + this.$route.params.server_id + '/generate_discord_link')
				.then(data => {
					this.loadingDiscord = false
					window.open(data.redirect_url, '_self')
				})
				.catch(error => {
					this.loadingDiscord = false
					Snackbar.error("Failed to add the integration")
				})
		},
		deleteIntegration(type, id) {
			this.$refs.deleteIntegrationModal.id = id
			this.$refs.deleteIntegrationModal.type = type
			this.$refs.deleteIntegrationModal.show = true
		},
		refresh() {
			this.getServerDetails()
		}
	}
}
</script>

<style scoped>

</style>